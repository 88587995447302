export const TREE_PRICE_IN_EUROS = 5;
export const PROTECTION_PRICE_PER_TREE_IN_EUROS = 6;
export const PICTURES_PRICE_PER_TREE_IN_EUROS = 5;

export const ICONS = {
    GREEN_MATURE_TREE: "img/green_mature_tree.svg",
    GREY_TREE: "img/grey_tree.svg",
    GREY_SEEDLING: "img/grey_seedling.svg",
    GREEN_SEEDLING: "img/green_seedling.svg",
    INFO: "img/info.svg"
}

export enum TREE_OWNER {
    ME,
    OTHER
}

export enum TREE_STATES {
    WAITING_TO_BE_PLANTED = "WAITING_TO_BE_PLANTED",
    ALIVE = "ALIVE",
    MOSTLY_LOST = "MOSTLY_LOST",
    LOST = "LOST"
}

export enum REDIRECT {
    PAYMENT = 'PAYMENT'
}

export enum ACTION_TYPES {
    SINGLE_TIME_DONATION = 'SINGLE_TIME_DONATION',
    MEMBERSHIP = 'MEMBERSHIP'
}

export const AVERAGE_TREE_AGE_WHEN_PLANTED = 2;

export const LEVELS = [
    {
        name: "BEGINNER",
        min: 0,
        max: 200,
        step: 40
    },
    {
        name: "EXPLORER",
        min: 200,
        max: 500,
        step: 60
    },
    {
        name: "ACTIVIST",
        min: 500,
        max: 1000,
        step: 100
    },
    {
        name: "TREE PROTECTOR",
        min: 1000,
        max: 1750,
        step: 150
    },
    {
        name: "NATURE PROTECTOR",
        min: 1750,
        max: 3000,
        step: 250
    },
    {
        name: "TREE HERO",
        min: 3000,
        max: 6000,
        step: 300
    },
    {
        name: "CONSERVATIONIST",
        min: 6000,
        max: 10000,
        step: 400
    },
    {
        name: "NATURE LEGEND",
        min: 10000,
        max: 16000,
        step: 500,
    },
    {
        name: "NATURE HERO",
        min: 16000,
        max: 20000,
        step: 800
    },
    {
        name: "CARBON NEGATIVE",
        min: 20000,
        max: 40000,
        step: 1250
    },
    {
        name: "CLIMATE LEGEND",
        min: 40000,
        max: 70000,
        step: 2500
    },
    {
        name: "CLIMATE HERO",
        min: 70000,
        max: Infinity,
        step: 5000
    }
]
