import { getAllUserHeroPoints, loginWithGoogle, redirectToDonation } from "../api/internal/api";
import { ICONS, TREE_STATES, ACTION_TYPES, AVERAGE_TREE_AGE_WHEN_PLANTED, REDIRECT, TREE_OWNER } from "../constants";
import { NormalUser } from "../logic/NormalUser";
import { ApiTreeResponse, GMapsPosition, ApiUserHeroPointsResponse } from "../types";
import { calculatePrice, calculateUserLevel } from "../utils/calculator";
import { getDomObjects } from "../utils/ui";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export class NormalUserApp {
    private user: NormalUser;
    private usersHeroPoints!: Array<ApiUserHeroPointsResponse>;
    private domObj: Record<string, HTMLElement | null>;
    private appURL = 'https://www.becarbonnegative.org';
    private map!: google.maps.Map;

    constructor() {
        this.user = new NormalUser();
        this.domObj = getDomObjects();

        this.addEventListeners();
        // wait 1 sec for the language file to be loaded and to enable tooltips
        setTimeout(() => {
            this.setDynamicDefaultContent();
            this.enableTooltips();
        }, 1000);

        if (this.user.userVisitedWebsiteAlready()) {
            setInterval(this.loggedInPeriodicCheck.bind(this), 10000);
            this.goToMainPage();
        }
    }

    setDynamicDefaultContent() {
        // @ts-ignore
        this.domObj?.modalDonationNumberOfTrees.textContent = this.getNumberOfSelectedTrees();
        // @ts-ignore
        document.getElementById("tooltip-protect-trees-info")?.setAttribute("data-bs-title", $.t('modalDonation.infoTreeCare'));
        // @ts-ignore
        document.getElementById("tooltip-tree-pictures-info")?.setAttribute("data-bs-title", $.t('modalDonation.infoTreePictures'));
        this.calculateAndShowPrice();
    }

    enableTooltips(){
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        //@ts-ignore
        [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
    }

    async goToMainPage() {
        this.domObj.carbonNegativeIntro?.classList.add("d-none");
        this.domObj.main?.classList.remove("d-none");
        this.domObj.accountDiv?.classList.remove("d-none");

        await this.user.attemptAutoLogin();

        const urlParams = new URLSearchParams(window.location.search);

        if (this.user.isLoggedIn()) {
            const redirect = urlParams.get('redirect');
            // if the user logs in after clicking on an action, for example donating then redirect
            if(redirect === 'payment') {
                const numberOfTrees = urlParams.get('numberOfTrees');
                this.redirectToPayment(numberOfTrees as string);
            }
            this.renderAccounInfo();
            this.renderMyTrees();
        }

        this.renderMap();
        this.renderLeaderboard();

        const paymentState = urlParams.get('payment_state');
        if(paymentState) {
            // @ts-ignore
            $(this.domObj.modalDonationResult).modal("show");
            if(paymentState === 'successful') {
                // @ts-ignore
                this.domObj.modalDonationResultText?.textContent = $.t('modalDonationResult.success');
            } else {
                // @ts-ignore
                this.domObj.modalDonationResultText?.textContent = $.t('modalDonationResult.fail');
            }
        }
    }

    loggedInPeriodicCheck() {
        if(!this.user.isLoggedIn() && this.domObj.loginWithGoogle?.classList.contains('d-none')) {
            window.location.reload();
        }
    }

    addEventListeners() {
        this.domObj.plantTreesBtn?.addEventListener('click', () => {
            // @ts-ignore
            $(this.domObj.modalDonation).modal('show');
        });
        this.domObj.modalDonationCta?.addEventListener('click', () => {
            if (this.user.isLoggedIn()) {
                this.redirectToPayment();
            } else {
                // @ts-ignore
                $(this.domObj.modalDonation).modal("hide");
                // @ts-ignore
                $("#modal-login-or-direct-donation").modal("show");
            }
        });
        this.domObj.loginWithGoogleFromModal?.addEventListener('click', () => {
            const redirect = REDIRECT.PAYMENT;
            loginWithGoogle(redirect, Number(this.getNumberOfSelectedTrees()))
        });
        this.domObj.directDonation?.addEventListener('click', () => {
            this.redirectToPayment();
        });
        this.domObj.introBtn?.addEventListener('click', async () => {
            await this.goToMainPage();
            this.user.setUserVisitiedWebsiteAlreadyToTrue();
        })
        // @ts-ignore
        $(this.domObj?.modalDonationProtectTreesCheckbox).on('change', () => {
            this.calculateAndShowPrice();
        })
        // @ts-ignore
        $(this.domObj?.modalDonationTreePicturesCheckbox).on('change', () => {
            this.calculateAndShowPrice();
        })
        // @ts-ignore
        $(this.domObj?.leaderboardTab).on('click', () => {
            if (this.user.isLoggedIn()) {
                const tr = document.getElementById("leaderboard-tr-" + this.user.getId());
                if(tr) {
                    tr.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });

                }
            }
        })
        // @ts-ignore
        $(this.domObj?.modalDonationNumberOfTreesInput).on('change', () => {
            const numberOfTreesSelected = Number(this.getNumberOfSelectedTrees());
            // @ts-ignore
            this.domObj?.modalDonationNumberOfTrees.textContent = numberOfTreesSelected

            if(numberOfTreesSelected === 0) {
                this.domObj.modalDonationCta?.setAttribute('disabled','');
            } else {
                this.domObj.modalDonationCta?.removeAttribute('disabled');
            }

            if (numberOfTreesSelected < 10) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "";
            } else if (numberOfTreesSelected >= 10 && numberOfTreesSelected < 25) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "😍";
            } else if (numberOfTreesSelected >= 25 && numberOfTreesSelected < 50) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "😍💚";
            } else if (numberOfTreesSelected >= 50 && numberOfTreesSelected < 100) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "😍💚🌳";
            } else if (numberOfTreesSelected >= 100 && numberOfTreesSelected < 250) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "🦸💚🦸";
            } else if (numberOfTreesSelected >= 250 && numberOfTreesSelected < 500) {
                // @ts-ignore
                this.domObj?.modalDonationNumberOfTreesRedonation?.textContent = "🦸🌳🌲💚🌲🌳🦸";
            }

            this.calculateAndShowPrice();
        });
        $('input[type=radio][name=donation-type]').on('change', function() {
            const donationTypeExplanationEl = document.getElementById('donation-type-explanation');
            const modalDonationPerMonth = document.getElementById("modal-donation-per-month");
            // @ts-ignore
            if (this.value == ACTION_TYPES.MEMBERSHIP) {
                // @ts-ignore
                donationTypeExplanationEl.textContent = $.t('modalDonation.infoMembership');
                // @ts-ignore
                modalDonationPerMonth.textContent = $.t('modalDonation.perMonth');
            }
            // @ts-ignore
            else if (this.value == ACTION_TYPES.SINGLE_TIME_DONATION) {
                // @ts-ignore
                donationTypeExplanationEl.textContent = $.t('modalDonation.infoSingleTimeDonation');
                // @ts-ignore
                modalDonationPerMonth.textContent = "";
            }
        });
    }

    calculateAndShowPrice() {
        const numberOfTrees = Number(this.getNumberOfSelectedTrees());
        const protectionAndCare = (<HTMLInputElement>this.domObj?.modalDonationProtectTreesCheckbox)?.checked;
        const pictureUpdates = (<HTMLInputElement>this.domObj?.modalDonationTreePicturesCheckbox)?.checked;;
        const price = calculatePrice(numberOfTrees, protectionAndCare, pictureUpdates);
        // @ts-ignore
        this.domObj?.modalDonationPrice?.textContent = price;
    }

    getNumberOfSelectedTrees() {
        return (<HTMLInputElement>this.domObj?.modalDonationNumberOfTreesInput)?.value;
    }

    redirectToPayment(numberOfTrees = '') {
        if(!numberOfTrees) {
            numberOfTrees = this.getNumberOfSelectedTrees();
        }
        redirectToDonation(Number(numberOfTrees));
    }

    /* TODO: Check if this function is still useful
    getTreeIconDependingOnAge(tree) {
        const plantingDateInMillis = new Date(tree.plantingDate).getTime();
        const now = Date.now();
        const threeMonthsInMillis = 7776000000;
        if (now - plantingDateInMillis > threeMonthsInMillis) {
            return ICONS.GREEN_MATURE_TREE;
        } else {
            return ICONS.GREEN_SEEDLING;
        }
    }
    */

    renderAccounInfo() {
        this.domObj.loginWithGoogle?.classList.add("d-none");
        this.domObj.myTreesTabLoginMessage?.classList.add("d-none");
        this.domObj.accountDropdown?.classList.remove("d-none");
        // @ts-ignore
        this.domObj.accountName?.textContent = "🦸 " + this.user.getName();
    }

    renderMyTrees() {
        const trees = this.user?.getTrees();
        if (!trees || trees.length === 0) {
            this.domObj.noTreesPlanted?.classList.remove("d-none");
            return;
        }

        this.domObj.treeList?.classList.remove("d-none");
        const treeNumbers = {
            toBePlanted: 0,
            alive: 0,
            mostlyLost: 0,
            lost: 0
        };
        trees.forEach(tree => {
            const treeListGroupItem = this.getTreeListGroupItem(tree);
            let treeListCard;
            if(tree.state === TREE_STATES.WAITING_TO_BE_PLANTED) {
                treeListCard = this.domObj.toBePlantedTrees;
                treeNumbers.toBePlanted += 1;
            } else if (tree.state === TREE_STATES.ALIVE) {
                treeListCard = this.domObj.aliveTrees;
                treeNumbers.alive += 1;
            } else if (tree.state === TREE_STATES.MOSTLY_LOST) {
                treeListCard = this.domObj.mostlyLostTrees;
                treeNumbers.mostlyLost += 1;
            } else if (tree.state === TREE_STATES.LOST) {
                treeListCard = this.domObj.lostTrees;
                treeNumbers.lost += 1;
            }

            treeListCard?.classList.remove("d-none");
            //@ts-ignore
            $(treeListCard).find('.list-group').append(treeListGroupItem);
        })

        $("#alive-trees .card-header").append(` (${treeNumbers.alive})`);
        $("#to-be-planted-trees .card-header").append(` (${treeNumbers.toBePlanted})`);
        $("#mostly-lost-trees .card-header").append(` (${treeNumbers.mostlyLost})`);
        $("#lost-trees .card-header").append(` (${treeNumbers.lost})`);
    }

    async renderMap(): Promise<void> {
        let startingPosition: GMapsPosition;

        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement, Marker } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
        const markers: Array<google.maps.marker.AdvancedMarkerElement> = [];
        // The map, centered at Uluru
        this.map = new Map(
            document.getElementById('map') as HTMLElement,
            {
                zoom: 15,
                mapId: 'CarbonNegativeMap',
                mapTypeId: 'satellite',
                tilt: 45
            }
        );

        const trees = this.user?.getTrees();
        const allOtherTrees = this.user?.getAllOtherTrees();

        const infoWindow = new google.maps.InfoWindow();

        const getTreeMarker = (tree: ApiTreeResponse, treeOwner: TREE_OWNER) => {
            if (tree.state === TREE_STATES.ALIVE || tree.state === TREE_STATES.MOSTLY_LOST) {
                const position: GMapsPosition = { lat: Number(tree.latitude), lng: Number(tree.longitude)};
                if(!startingPosition) {
                    startingPosition = position;
                }

                let content;
                if (treeOwner === TREE_OWNER.ME) {
                    const greenTreeImg = document.createElement('img');
                    greenTreeImg.src = `${this.appURL}/img/green_tree_icon.svg`;
                    greenTreeImg.width = 50;
                    greenTreeImg.height = 50;
                    content = greenTreeImg;
                } else {
                    const greenTreeOpacityImg = document.createElement('img');
                    greenTreeOpacityImg.src = `${this.appURL}/img/green_tree_icon_0_7_opacity.svg`;
                    greenTreeOpacityImg.width = 30;
                    greenTreeOpacityImg.height = 30;
                    content = greenTreeOpacityImg;
                }

                // add a marker
                const marker = new AdvancedMarkerElement({
                    map: this.map,
                    position,
                    content,
                    title: `${tree.id} ${tree.species || ''}`,
                });

                // @ts-ignore
                marker.addListener('click', () => {
                    infoWindow.close();
                    const container = document.createElement('div');
                    const stateDiv = document.createElement('div');
                    // @ts-ignore
                    let state = $.t("common.treeStates.alive");
                    if(tree.state === TREE_STATES.MOSTLY_LOST) {
                        // @ts-ignore
                        state = $.t("common.treeStates.mostlyLost");
                    }
                    // @ts-ignore
                    stateDiv.textContent = `${$.t("common.state")}: ${state}`;

                    const name = document.createElement("h5");
                    let owner = '';
                    if (treeOwner === TREE_OWNER.ME) {
                        // @ts-ignore
                        owner = `(${$.t('common.yours')})`;
                    }
                    name.textContent = `${marker.title} ${owner}`;

                    // @ts-ignore
                    const plantingDate = new Date(tree.planting_date).getTime();
                    const now = Date.now();
                    const oneYearInMillis = 31557600000;
                    const treeAge = Math.trunc((now - plantingDate) / oneYearInMillis);
                    const treeAgeDiv = document.createElement('div');
                    // @ts-ignore
                    treeAgeDiv.textContent = `${$.t("common.age")}: ${treeAge + AVERAGE_TREE_AGE_WHEN_PLANTED} ${$.t("common.years")}`;

                    container.appendChild(name);
                    container.appendChild(stateDiv);
                    container.appendChild(treeAgeDiv);

                    // @ts-ignore
                    infoWindow.setContent(container);
                    infoWindow.open(marker.map, marker);
                });

                markers.push(marker);
            }
        };

        if (trees?.length) {
            trees.forEach(tree => getTreeMarker(tree, TREE_OWNER.ME));
        }

        if (allOtherTrees?.length) {
            allOtherTrees.forEach(tree => getTreeMarker(tree, TREE_OWNER.OTHER));
        }

        // @ts-ignore
        this.map.setCenter(startingPosition);

        const renderer = {
            render: ({ count, position }: { count: number; position: GMapsPosition}) => {
                const content = document.createElement('div');
                const forestImg = document.createElement('img');
                forestImg.src = `${this.appURL}/img/forest_icon.svg`;
                forestImg.width = 50;
                forestImg.height = 50;
                const countLabel = document.createElement('label');
                countLabel.textContent = String(count);
                countLabel.style.fontSize = "14px";
                countLabel.style.fontFamily = "cursive";
                countLabel.style.fontWeight = "bold";
                countLabel.style.color = "white";
                countLabel.style.position = "absolute";
                countLabel.style.top = "6px";
                countLabel.style.right = "22px";

                content.appendChild(forestImg);
                content.appendChild(countLabel);
                return new google.maps.marker.AdvancedMarkerElement({
                    position,
                    content,
                    // adjust zIndex to be above other markers
                    zIndex: Number(Marker.MAX_ZINDEX) + count,
                });
            }
        };

        // @ts-ignore
        new MarkerClusterer({ markers, map: this.map, renderer });
    }

    async renderLeaderboard() {
        this.usersHeroPoints = await getAllUserHeroPoints();
        // @ts-ignore
        this.domObj.tbodyLeaderboard?.innerHTML = '';
        this.usersHeroPoints.forEach((userPoints, index) => {
            let trClass = '';
            let isYou = '';
            if(this.user.isLoggedIn() && this.user.getId() === userPoints.id) {
                trClass = 'class="fs-4 fst-italic border-1"';
                // @ts-ignore
                isYou = ` (${$.t("common.you")})`;
            }
            const nickname = userPoints.name.split(" ").map(word => word[0]).join("");
            const tableRow = $(
            `<tr id='leaderboard-tr-${userPoints.id}' ${trClass}>
                <td class="col-1"><span class="badge rounded-pill">${index + 1}</span></td>
                <td class="col-3">${nickname}-${userPoints.id}${isYou}</td>
                <td class="col-3">
                    <span class="badge rounded-pill text-bg-success fs-6">${userPoints.hero_points}</span>
                </td>
                <td class="col-5">
                    ${calculateUserLevel(userPoints.hero_points)}
                </td>
            </tr>`);

            // @ts-ignore
            $(this.domObj.tbodyLeaderboard).append(tableRow);
        })
    }

    getTreeListGroupItem(tree: ApiTreeResponse) {
        let species = $(`<span class="col mx-1 badge text-bg-light"></span>`);
        let mapButton = $('<span class="col mx-1"></span>');
        let pictureButton = $('<span class="col mx-1"></span>');

        if(tree?.species) {
            species.text(tree.species);
        }
        if(tree?.picture_updates) {
            // @ts-ignore
            const btn = $(`<button class="btn btn-sm btn-success">${$.t('common.pictures')}</button>`);
            pictureButton.append(btn);
            btn.on('click', () => {
                console.log('see pictures clicked for tree ID: ' + tree.id);
            });
        }
        if(tree?.latitude && tree?.longitude && tree.state !== TREE_STATES.LOST) {
            // @ts-ignore
            const btn = $(`<button class="btn btn-sm btn-success">${$.t('common.seeOnMap')}</button>`);
            mapButton.append(btn);
            btn.on('click', () => {
                const position: GMapsPosition = {
                    lat: Number(tree.latitude) as number,
                    lng: Number(tree.longitude)  as number
                }
                this.map.setCenter(position);
                this.map.setZoom(18);
                //@ts-ignore
                $(this.domObj.mapTab).trigger('click');
            });
        }

        //@ts-ignore
        const li = $(`<li class="list-group-item"></li>`);
        li.append(`<span class="col badge text-bg-light">🌳 ID ${tree.id}</span>`);
        li.append(species);
        li.append(mapButton);
        //li.append(pictureButton);
        return li;
    }
}
