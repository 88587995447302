import { TREE_STATES } from "../constants"

export const getLocaleOfTreeState = (state: TREE_STATES) => {
    if(state === TREE_STATES.ALIVE) {
        //@ts-ignore
        return $.t('common.treeStates.alive');
    } else if(state === TREE_STATES.MOSTLY_LOST) {
        //@ts-ignore
        return $.t('common.treeStates.mostlyLost');
    } else if(state === TREE_STATES.LOST) {
        //@ts-ignore
        return $.t('common.treeStates.lost');
    }
    else if(state === TREE_STATES.WAITING_TO_BE_PLANTED) {
        //@ts-ignore
        return $.t('common.treeStates.waitingToBePlanted');
    }
}


export const getDomObjects = (): Record<string, HTMLElement | null> => {
    return {
        inProgressList: document.getElementById("in-progress"),
        plantTreesBtn: document.getElementById("plant-trees-btn"),
        introBtn: document.getElementById("intro-button"),
        main: document.getElementById("main"),
        accountDiv: document.getElementById("account-div"),
        loginWithGoogle: document.getElementById("login-with-google"),
        accountDropdown: document.getElementById("account-dropdown"),
        accountName: document.getElementById("account-name"),
        carbonNegativeIntro: document.getElementById("carbon-negative-intro"),
        navigation: document.getElementById("navigation"),
        myTreesTabLoginMessage: document.getElementById("my-trees-tab-login-message"),
        mapTab: document.getElementById("map-tab"),
        treeList: document.getElementById("tree-list"),
        toBePlantedTrees: document.getElementById("to-be-planted-trees"),
        aliveTrees: document.getElementById("alive-trees"),
        mostlyLostTrees: document.getElementById("mostly-lost-trees"),
        lostTrees: document.getElementById("lost-trees"),
        noTreesPlanted: document.getElementById("no-trees-planted"),
        leaderboardTab: document.getElementById("leaderboard-tab"),
        tbodyLeaderboard: document.getElementById("tbody-leaderboard"),
        modalDonation: document.getElementById("modal-donation"),
        modalDonationTitle: document.getElementById("modal-donation-title"),
        modalDonationNumber: document.getElementById("modal-donation-number"),
        modalDonationType: document.getElementById("modal-donation-type"),
        modalDonationCta: document.getElementById("modal-donation-cta"),
        donationsTbody: document.getElementById("donations-tbody"),
        modalDonationNumberOfTrees: document.getElementById("modal-donation-number-of-trees"),
        modalDonationNumberOfTreesInput: document.getElementById("modal-donation-number-of-trees-input"),
        modalDonationNumberOfTreesRedonation: document.getElementById("modal-donation-number-of-trees-redonation"),
        modalDonationProtectTreesCheckbox: document.getElementById("protect-trees-checkbox"),
        modalDonationTreePicturesCheckbox: document.getElementById("tree-pictures-checkbox"),
        modalDonationPrice: document.getElementById("modal-donation-price"),
        loginWithGoogleFromModal: document.getElementById("login-with-google-from-modal"),
        directDonation: document.getElementById("direct-donation"),
        modalDonationResult: document.getElementById("modal-donation-result"),
        modalDonationResultText: document.getElementById("modal-donation-result-text")
    }
}
