import { getCsrfToken } from "./api/internal/api";
import { NormalUserApp } from "./app/NormalUserApp";
import { PlanterUserApp } from "./app/PlanterUserApp";

const url = window.location.href;
if (url.includes('.org/planter')) {
    let planterToken = url.replace('https://www.becarbonnegative.org/planter/','').replace('/','');
    new PlanterUserApp(planterToken);
} else {
    new NormalUserApp();
}

getCsrfToken().then(csrf => {
    $('[name="_token"]').val(csrf);
});
