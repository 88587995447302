import { planterDeleteTree, planterInsertTree, planterUpdateTree } from "../api/internal/api";
import { PlanterUser } from "../logic/PlanterUser";
import { GMapsPosition } from "../types";
import { getLocaleOfTreeState } from "../utils/ui";

export class PlanterUserApp {
    private user: PlanterUser;
    private map!: google.maps.Map;
    private infoWindow!: google.maps.InfoWindow;

    constructor(planterToken: string) {
        this.user = new PlanterUser();

        this.addEventListeners();
        this.user.attemptAutoLogin(planterToken).then(() => {
            this.setUserDataInTheUI();
            this.initMap();
        });
    }

    private addEventListeners() {
        $("#plant-tree-btn").on('click', () => {
            //@ts-ignore
            $("#modal-plant-tree").modal("show");
            $("#select-option-edit-tree-state").addClass('d-none');
            $("#tree-id-input").val('');
            $('#modal-plant-tree [name=species]').val('');
            $('#modal-plant-tree [name=latitude]').val('');
            $('#modal-plant-tree [name=longitude]').val('');
            $('#modal-plant-tree [name=protection_description]').val('');
            $('#modal-plant-tree-latitude').text('');
            $('#modal-plant-tree-longitude').text('');

            //@ts-ignore
            this.infoWindow.setContent($.t("planter.clickOnMapToSetTreeLocation"));
        });

        $("#modal-plant-tree-form").on('submit', async event => {
            event.preventDefault();
            const species = $('#modal-plant-tree [name=species]').val();
            const lat = $('#modal-plant-tree [name=latitude]').val();
            const lng = $('#modal-plant-tree [name=longitude]').val();
            if (!species || !lat || !lng) return;
            const form = document.getElementById('modal-plant-tree-form') as HTMLFormElement;
            const data = new FormData(form);

            //@ts-ignore
            $("#modal-plant-tree").modal('hide');
            try {
                // if tree id is empty it is an insert, otherwise an edit
                if( $("#tree-id-input").val() === '') await planterInsertTree(data);
                else await planterUpdateTree(data);
                this.setTreesInTheUI();
            } catch (err) {
                //@ts-ignore
                $("#error-modal").modal('show');
            }
        });

        $("#modal-delete-tree-form").on('submit', async event => {
            event.preventDefault();
            const form = document.getElementById('modal-delete-tree-form') as HTMLFormElement;
            const data = new FormData(form);

            //@ts-ignore
            $("#delete-tree-confirm-modal").modal('hide');
            try {
                await planterDeleteTree(data);
                this.setTreesInTheUI();
            } catch (err) {
                //@ts-ignore
                $("#error-modal").modal('show');
            }
        });

        $("#set-location-of-tree-btn").on('click', () => {
            //@ts-ignore
            $("#modal-plant-tree").modal('hide');
            //@ts-ignore
            $("#modal-tree-location").modal('show');
        });

        $("#modal-tree-location-done").on('click', () => {
            //@ts-ignore
            $("#modal-tree-location").modal('hide');
            //@ts-ignore
            $("#modal-plant-tree").modal('show');
        });
    }

    private async setUserDataInTheUI() {
        $("#account-name").text(this.user.getName());
        $("#account-email").text(this.user.getEmail());
        $(".planter-token-input").val(this.user.getPlanterToken());
        this.setTreesInTheUI();
    }

    private updateTreeModalSetup(treeId: string) {
        //@ts-ignore
        $("#modal-plant-tree").modal("show");
        $("#select-option-edit-tree-state").removeClass('d-none');
        $("#tree-id-input").val(treeId);
        const tree = this.user.getTrees()?.find(tree => Number(treeId) === Number(tree.id))
        $('#modal-plant-tree [name=species]').val(tree?.species as string);
        $('#modal-plant-tree [name=latitude]').val(tree?.latitude as string);
        $('#modal-plant-tree [name=longitude]').val(tree?.longitude as string);
        $('#modal-plant-tree-latitude').text(tree?.latitude as string);
        $('#modal-plant-tree-longitude').text(tree?.longitude as string);
        this.map.setCenter({
            lat: Number(tree?.latitude),
            lng: Number(tree?.longitude)
        });

        this.infoWindow.setContent(
            //@ts-ignore
            `${$.t("planter.treeLocationSetTo")} - ${$.t("common.latitude")}: ${tree?.latitude}, ${$.t("common.longitude")}: ${tree?.longitude}`
        );
        $('#modal-plant-tree [name=protection_description]').val(tree?.protection_description as string);
        $('#tree-state-select').val(tree?.state as string).trigger('change');
    }

    private async setTreesInTheUI() {
        await this.user.synchTrees();
        const trees = this.user.getTrees();
        $("#tbody-planted-by-you").empty();
        trees?.forEach(tree => {
            const tr = $("<tr></tr>");
            const userId = tree.user_id;
            tr.append(`
                <th>${tree.id}</th>
                <th>${tree.species}</th>
                <th>${getLocaleOfTreeState(tree.state)}</th>
                <th>${userId !== null? userId : ''}</th>
            `);
            //@ts-ignore
            const editButton = $(`<th><button class="btn btn-light">${$.t('common.edit')}</button></th>`);
            editButton.on('click', () => {
                this.updateTreeModalSetup(String(tree.id));
            });
            tr.append(editButton);
            //@ts-ignore
            const deleteButton = $(`<th><button class="btn btn-danger">${$.t('common.delete')}</button></th>`);
            deleteButton.on('click', () => {
                $("#delete-tree-input-id").val(String(tree.id));
                //@ts-ignore
                $("#delete-tree-confirm-modal").modal("show");
                if(tree.user_id) {
                    $("#declare-tree-as-never-planted").removeClass("d-none");
                } else {
                    $("#declare-tree-as-never-planted").addClass("d-none");
                }
            });
            tr.append(deleteButton);
            $("#tbody-planted-by-you").append(tr);
        })
    }

    private async initMap() {
        // Request needed libraries.
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const trees = this.user.getTrees();

        let startingLatlng = { lat: 40.74665, lng: 14.64582 };
        if(trees?.length) {
            const tree = trees[0];
            startingLatlng = { lat: Number(tree.latitude), lng: Number(tree.longitude) };
        }

        this.map = new Map(
            document.getElementById("tree-location-map")!,
            {
                zoom: 14,
                center: startingLatlng,
            });

        // Create the initial InfoWindow.
        this.infoWindow = new google.maps.InfoWindow({
            //@ts-ignore
            content: $.t("planter.clickOnMapToSetTreeLocation"),
            position: startingLatlng,
        });

        this.infoWindow.open(this.map);

        // Configure the click listener.
        this.map.addListener("click", ({ latLng }: {latLng: google.maps.LatLng}) => {
          // Close the current InfoWindow.
          this.infoWindow.close();

          // Create a new InfoWindow.
          this.infoWindow = new google.maps.InfoWindow({
            position: latLng,
          });
          this.infoWindow.setContent(
            //@ts-ignore
            `${$.t("planter.treeLocationSetTo")} - ${$.t("common.latitude")}: ${latLng.lat()}, ${$.t("common.longitude")}: ${latLng.lng()}`
          );

          $('#modal-plant-tree [name=latitude]').val(latLng.lat());
          $('#modal-plant-tree [name=longitude]').val(latLng.lng());
          $('#modal-plant-tree-latitude').text(latLng.lat());
          $('#modal-plant-tree-longitude').text(latLng.lng());
          this.infoWindow.open(this.map);
        });
      }
}
