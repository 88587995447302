import { getPlanterUser, getPlanterUserTrees } from "../api/internal/api";
import { ApiPlanterUserResponse, ApiTreeResponse } from "../types";

export class PlanterUser {
    private token!: string;
    private name: string = '';
    private email: string = '';
    private trees?: Array<ApiTreeResponse> = [];

    async attemptAutoLogin(planterToken: string) {
        const userData = await getPlanterUser(planterToken) as ApiPlanterUserResponse;
        if (userData) {
            this.token = userData.token;
            this.name = userData.name;
            this.email = userData.email;
        }
    }

    async synchTrees() {
        this.trees = await getPlanterUserTrees(this.token);
    }

    getTrees() {
        return this.trees;
    }

    getName() {
        return this.name;
    }

    getEmail() {
        return this.email;
    }

    getPlanterToken() {
        return this.token;
    }
}
