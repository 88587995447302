import { LEVELS, PICTURES_PRICE_PER_TREE_IN_EUROS, PROTECTION_PRICE_PER_TREE_IN_EUROS, TREE_PRICE_IN_EUROS } from "../constants";

export const calculatePrice = (numberOfTrees: number, protectionAndCare: boolean, pictureUpdates: boolean): number => {
    const protectionAndCarePrice = protectionAndCare? PROTECTION_PRICE_PER_TREE_IN_EUROS : 0;
    const pictureUpdatesPrice = pictureUpdates? PICTURES_PRICE_PER_TREE_IN_EUROS : 0;
    return Math.ceil(numberOfTrees * (TREE_PRICE_IN_EUROS + protectionAndCarePrice + pictureUpdatesPrice));
}

export const calculateUserLevel = (userHeroPoints: number) => {
    const level = LEVELS.find(level => userHeroPoints >= level.min && userHeroPoints < level.max) || LEVELS[0];

    const subLevelNumber = Math.trunc((userHeroPoints - level?.min) / level?.step);
    return `${level.name}-${subLevelNumber}`;
}
