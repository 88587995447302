import { getAllOtherTrees, getTrees, getNormalUser } from "../api/internal/api";
import { LEVELS } from "../constants";
import { ApiNormalUserResponse, ApiTreeResponse } from "../types";

export class NormalUser {
    private loggedIn: boolean = false;
    private heroPoints: number = 0;
    private id!: number;
    private name: string = '';
    private email: string = '';
    private trees?: Array<ApiTreeResponse> = [];
    private allOtherTrees?: Array<ApiTreeResponse> = [];
    private level?: string;

    async attemptAutoLogin() {
        // call the set user data immediately
        await this.userDataSyncAndLogin();
        this.allOtherTrees = await getAllOtherTrees();
        // sync the user data and logged in state every 10 seconds
        setInterval(this.userDataSyncAndLogin.bind(this), 10000);
    }

    getId() {
        return this.id;
    }

    getTrees() {
        return this.trees;
    }

    getAllOtherTrees() {
        return this.allOtherTrees;
    }

    getName() {
        return this.name;
    }

    getEmail() {
        return this.email;
    }

    userVisitedWebsiteAlready(): boolean {
        return localStorage.getItem('userVisitedWebsiteAlready') === 'true';
    }

    setUserVisitiedWebsiteAlreadyToTrue() {
        localStorage.setItem('userVisitedWebsiteAlready', 'true');
    }

    async userDataSyncAndLogin() {
        const userData = await getNormalUser();
        if (userData) {
            this.setUserData(userData as ApiNormalUserResponse);
            this.trees = await getTrees();
        } else {
            this.loggedIn = false;
        }
    }

    private setUserData(userData: ApiNormalUserResponse) {
        this.loggedIn = true;
        this.id = userData.id;
        this.name = userData.name;
        this.email = userData.email;
        this.heroPoints = userData.hero_points || 0;
    }

    isLoggedIn(): boolean {
        return this.loggedIn;
    }
}
