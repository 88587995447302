import { ApiTreeResponse, ApiUserHeroPointsResponse, ApiNormalUserResponse, ApiPlanterUserResponse } from "../../types";

const api = {
    getJson: async (url: string) => {
        const response = await fetch(url, { mode:  'cors'});
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }

        return response.json();
    }
}

// ALL USERS APIS
export const getCsrfToken = async () => {
    const url = "https://www.becarbonnegative.org/csrftoken";
    const response = await fetch(url, { mode:  'cors'});
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return response.text();
}

// NORMAL USER APIS
export const getNormalUser = async (): Promise<string | ApiNormalUserResponse> => {
    const url = "https://www.becarbonnegative.org/user/me";
    const response = await fetch(url, { mode:  'cors'});
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    const responseText = await response.text();
    if (responseText !== '') {
        return JSON.parse(responseText);
    }

    return responseText;
}

export const getAllUserHeroPoints = async (): Promise<Array<ApiUserHeroPointsResponse>> => {
    return api.getJson("https://www.becarbonnegative.org/user/hero_points/list/all");
}

export const getTrees = async (): Promise<[] | Array<ApiTreeResponse>> => {
    return api.getJson("https://www.becarbonnegative.org/tree/list/me");
}

export const getAllOtherTrees = async (): Promise<[] | Array<ApiTreeResponse>> => {
    return api.getJson("https://www.becarbonnegative.org/tree/list/others");
}

export const loginWithGoogle = async (redirect = '', numberOfTrees = 0) => {
    window.location.href = `https://www.becarbonnegative.org/auth/google?redirect=${redirect}&numberOfTrees=${numberOfTrees}`;
}

export const redirectToDonation = (numberOfTrees:number) => {
    window.location.href = `https://www.becarbonnegative.org/pay?numberOfTrees=${numberOfTrees}`;
}


// PLANTER USER APIS
export const PLANTER_DELETE_TREE_ENDPOINT_URL = `https://www.becarbonnegative.org/tree/planter/delete`;

export const getPlanterUser = async (planterToken: string): Promise<string | ApiPlanterUserResponse> => {
    const url = `https://www.becarbonnegative.org/planter/me/${planterToken}`;
    const response = await fetch(url, { mode:  'cors'});
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    const responseText = await response.text();
    if (responseText !== '') {
        return JSON.parse(responseText);
    }

    return responseText;
}

export const getPlanterUserTrees = async (planterToken: string): Promise<[] | Array<ApiTreeResponse>> => {
    return api.getJson(`https://www.becarbonnegative.org/tree/planter/list/me/${planterToken}`);
}

export const planterInsertTree = async (form: FormData): Promise<boolean> => {
    const url = `https://www.becarbonnegative.org/tree/planter/insert`;
    return planterTreeOperation(url, form);
}

export const planterUpdateTree = async (form: FormData): Promise<boolean> => {
    const url = `https://www.becarbonnegative.org/tree/planter/update`;
    // for some reason Laravel returns 419 if the request is a PUT request, therefore we need to do a POST
    // with _method PUT set in this way. On Backend it will be managed by Laravel as a PUT
    form.append("_method", 'PUT');
    return planterTreeOperation(url, form);
}

export const planterDeleteTree = async (form: FormData): Promise<boolean> => {
    const url = `https://www.becarbonnegative.org/tree/planter/delete`;
    // for some reason Laravel returns 419 if the request is a DELETE request, therefore we need to do a POST
    // with _method DELETE set in this way. On Backend it will be managed by Laravel as a DELETE
    form.append("_method", 'DELETE');
    return planterTreeOperation(url, form);
}

const planterTreeOperation = async (url: string, form: FormData) => {
    const response = await fetch(url,
        {
            method: 'POST',
            mode:  'cors',
            body: form,
        });
    if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
    }

    return true;
}

